import React from "react";
import { PropTypes } from "prop-types";
import { DialogContent, DialogContentText, Typography, withStyles, Avatar } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";

import { Flex } from "../../../_common";
import WhatsAppIcon from "../../whatsapp/WhatsAppIcon";

const styles = {
  flexAtividadeWorkflow: {
    margin: "1em 0 1em",
    alignItems: "center",
  },
  typographyAtividadeWorkflow: {
    fontSize: "1rem",
    color: "black",
  },
  avatar: {
    margin: "0 1em 0 0",
    backgroundColor: "rgb(118, 118, 118)",
    size: "32px",
  },
};

const DialogCancelaWorkflow = ({ icon, desc, classes }) => (
  <DialogContent>
    <DialogContentText>
      Ao excluir esta atividade, a ação encadeada abaixo será cancelada assim como as seguintes:
    </DialogContentText>
    <Flex className={classes.flexAtividadeWorkflow}>
      <Avatar id="avatarAtividadeWorkflow" className={classes.avatar}>
        {icon !== "whatsapp" ? <Icon>{icon}</Icon> : <WhatsAppIcon color="#ffffff" size={18} />}
      </Avatar>
      <Typography className={classes.typographyAtividadeWorkflow}>{desc}</Typography>
    </Flex>
  </DialogContent>
);

DialogCancelaWorkflow.propTypes = {
  icon: PropTypes.node,
  desc: PropTypes.string,
  classes: PropTypes.object,
};

export default withStyles(styles)(DialogCancelaWorkflow);
