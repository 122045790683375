import React from "react";
import PropTypes from "prop-types";

import { VertMenu } from "../../../../components";
import AtividadeRetornarLigacao from "../RetornarLigacao/RetornarLigacao";
import { TIPO_GATE, SITUACAO_LEAD } from "../../../../_common/constantes";
import { IniciarFiltro, EnviarEmail } from "../../../lead/components";

function MenuAcoesRapidas({ atividade = {}, setModalLigacaoIsOpen }) {
  const dasabilitarIniciarFiltro =
    !atividade.lead ||
    atividade.lead?.etapa?.tipoGate !== TIPO_GATE.FILTRO ||
    atividade.lead?.situacao === SITUACAO_LEAD.DESCARTADO;
  return (
    <VertMenu keepMounted>
      <AtividadeRetornarLigacao
        atividade={atividade}
        useIconMenu
        ignoreValidationActivityType
        alwaysShow
        setModalLigacaoIsOpen={setModalLigacaoIsOpen}
      />
      <EnviarEmail lead={atividade.lead} useIconMenu />
      <IniciarFiltro lead={atividade.lead} useIconMenu useDisable={dasabilitarIniciarFiltro} />
    </VertMenu>
  );
}

MenuAcoesRapidas.propTypes = {
  setModalLigacaoIsOpen: PropTypes.func,
  atividade: PropTypes.shape({
    nuTipoId: PropTypes.number.isRequired,
    status: PropTypes.number,
    lead: PropTypes.shape({
      id: PropTypes.number,
      nome: PropTypes.string,
      situacao: PropTypes.number,
      reuniao: PropTypes.shape({
        id: PropTypes.number,
        confirmacao: PropTypes.bool,
      }),
      contatos: PropTypes.arrayOf(
        PropTypes.shape({
          principal: PropTypes.bool,
          nome: PropTypes.string,
          cargo: PropTypes.string,
          email: PropTypes.string,
        })
      ).isRequired,
      etapa: PropTypes.shape({
        id: PropTypes.number.isRequired,
        permissoes: PropTypes.arrayOf(PropTypes.shape({ tipoPerfil: PropTypes.number, permissao: PropTypes.number })),
        proximaEtapaId: PropTypes.number,
        questionario: PropTypes.shape({ id: PropTypes.number, tipo: PropTypes.number }),
        tipoGate: PropTypes.number,
      }).isRequired,
    }),
  }),
};

export default MenuAcoesRapidas;
