import React from "react";
import { PropTypes } from "prop-types";
import { useIntl } from "react-intl";

import useConcluirAtividade from "./useConcluirAtividade";
import ConcluirAtividadeButton from "./Button";
import ModalAtividadeNewCont from "../ModalAtividadeNewCont";
import ModalConcluirAtividadeEncadeada from "../../../atividades/modals/ModalConcluirAtividadeEncadeada";

function ConcluirAtividade({ atividade, callback, children, callbackConcluir, ...props }) {
  const intl = useIntl();
  const { concluirAtividade, modalConcluirAtividadeProps, verificaAcaoEncadeadaWF } = useConcluirAtividade(
    callbackConcluir
  );

  const handleClickConcluir = () =>
    ModalConcluirAtividadeEncadeada(atividade, concluirAtividade, intl, verificaAcaoEncadeadaWF);

  return (
    <>
      {children ? (
        children({
          concluir: () => handleClickConcluir(),
        })
      ) : (
        <ConcluirAtividadeButton onClick={() => handleClickConcluir()} {...props} />
      )}
      {modalConcluirAtividadeProps.open && (
        <ModalAtividadeNewCont {...modalConcluirAtividadeProps} callback={callback} />
      )}
    </>
  );
}

ConcluirAtividade.propTypes = {
  atividade: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lead: PropTypes.shape({
      id: PropTypes.number,
      nome: PropTypes.string,
    }),
  }),
  /** Callback da modal de próixima atividade */
  callback: PropTypes.func,
  /** Função executada após chamar o endpoint que conclui a atividade */
  callbackConcluir: PropTypes.func,
  children: PropTypes.func,
};

export default ConcluirAtividade;
