import React from "react";
import { useIntl } from "react-intl";
import { PropTypes } from "prop-types";
import { DialogContent, DialogContentText, Typography, withStyles, Avatar } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";

import { Flex } from "../../../_common";
import WhatsAppIcon from "../../whatsapp/WhatsAppIcon";

function DialogConcluirAtividadeWorkflow({ icon, desc, classes }) {
  const intl = useIntl();

  return (
    <DialogContent>
      <DialogContentText>
        {intl.formatMessage({
          defaultMessage: "Deseja concluir essa atividade e realizar a criação das seguintes ações?",
        })}
      </DialogContentText>
      <Flex className={classes.flexAtividadeWorkflow}>
        <Avatar id="avatarAtividadeWorkflow" className={classes.avatar}>
          {icon === "whatsapp" ? <WhatsAppIcon color="#ffffff" size={18} /> : <Icon>{icon}</Icon>}
        </Avatar>
        <Typography className={classes.typographyAtividadeWorkflow}>{desc}</Typography>
      </Flex>
    </DialogContent>
  );
}

DialogConcluirAtividadeWorkflow.propTypes = {
  icon: PropTypes.node,
  desc: PropTypes.string,
  classes: PropTypes.object,
};

export default withStyles({
  flexAtividadeWorkflow: {
    margin: "1em 0 1em",
    alignItems: "center",
  },
  typographyAtividadeWorkflow: {
    fontSize: "1rem",
    color: "black",
  },
  avatar: {
    margin: "0 1em 0 0",
    backgroundColor: "rgb(118, 118, 118)",
    size: "32px",
  },
})(DialogConcluirAtividadeWorkflow);
