import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import { useIntl } from "react-intl";

import { useUsuario } from "../../../../hooks";
import { ActionButton } from "../../../../components";

ConcluirAtividadeButton.propTypes = {
  styleIcon: PropTypes.object,
  useIconWithLabel: PropTypes.bool,
  onClick: PropTypes.func,
};

function ConcluirAtividadeButton({ styleIcon, useIconWithLabel = false, ...props }) {
  const { isGerente } = useUsuario();
  const intl = useIntl();

  if (isGerente) return null;

  return (
    <>
      {useIconWithLabel ? (
        <ActionButton
          id="btn-concluir-atividade"
          type="menu"
          onClick={() => props.onClick()}
          icon={<Check />}
          label={intl.formatMessage({ defaultMessage: "Concluir" })}
        />
      ) : (
        <IconButton {...props}>
          <Check style={styleIcon} />
        </IconButton>
      )}
    </>
  );
}

export default ConcluirAtividadeButton;
