export const STATUS_ATRASADAS = 0;
export const STATUS_PARA_HOJE = 1;
export const STATUS_CONCLUIDAS = 2;
export const STATUS_PLANEJADAS = 3;

export const COR_FILTRO_ATIVIDADE_ATRASADA = "#EC4A2C";
export const COR_FILTRO_ATIVIDADE_CONCLUIDA = "#098348";
export const COR_FILTRO_ATIVIDADE_PLANEJADA = "#5e5e5e";
export const COR_FILTRO_ATIVIDADE_HOJE = "#f1be24";

export const COR_FILTRO_ATIVIDADE_DESATIVADO = "#eee";

export const COR_FONTE_ATIVIDADE_HOJE = "#FF9C00";
export const COR_FONTE_ATIVIDADE_PLANEJADA = "#5e5e5e";
export const COR_FONTE_ATIVIDADE_ATRASADA = "#EC4A2C";
export const COR_FONTE_ATIVIDADE_CONCLUIDA = "#098348";
