import React from "react";
import PropTypes from "prop-types";
import DialogConcluirAtividadeWorkflow from "../../atividade/components/DialogConcluirAtividadeWorkflow";
import { createDialogConfirm } from "../../../_common";

async function ModalConcluirAtividadeEncadeada(atividade, concluirAtividade, intl, verificaAcaoEncadeadaWF) {
  const acaoEncadeada = await verificaAcaoEncadeadaWF(atividade);

  if (acaoEncadeada) {
    return createDialogConfirm({
      text: intl.formatMessage({
        defaultMessage: "Deseja concluir essa atividade e realizar a criação das seguintes ações?",
      }),
      title: intl.formatMessage({ defaultMessage: "Ações encadeadas (Workflow)" }),
      acceptLabel: intl.formatMessage({ defaultMessage: "Criar próxima ação" }),
      cancelLabel: intl.formatMessage({ defaultMessage: "Cancelar Workflow" }),
      dialogProps: {
        maxWidth: "md",
        fullWidth: true,
      },
      content: <DialogConcluirAtividadeWorkflow icon={acaoEncadeada.icone} desc={acaoEncadeada.descricao} />,
      callback: accepted => concluirAtividade(atividade, !accepted),
    });
  }

  return concluirAtividade(atividade);
}

ModalConcluirAtividadeEncadeada.propTypes = {
  atividade: PropTypes.object.isRequired,
  concluirAtividade: PropTypes.func,
};

export default ModalConcluirAtividadeEncadeada;
