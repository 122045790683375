import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import axios from "axios";
import { debounce, identity } from "lodash";
import Moment from "moment";
import { objectKeysToCamelCase } from "../../../_common/utils/string";
import createSnackBar from "../../../_common/utils/snackbar/createSnackbar";

import ModalAtividadeNew from "./ModalAtividadeNew";
import { AjaxBlackout, createDialogConfirm } from "../../../_common";
import { maybeCallback } from "../../../_common/utils/fp";
import ModalConflito from "./ModalConflito";

const INITIAL_VALUES = {
  id: null,
  dataSourceLead: [],
  contatosSelecionados: [],
  textoPesquisaLead: "",
  notificacao: 0,
  preVendedorSelecionado: 0,
  lead: "",
  leadId: "",
  tipoAtividadeSelecionadaId: 0,
  salvando: false,
  dia: Moment().format("YYYY-MM-DD"),
  horas: "",
  descricaoTipo: "",
  observacoes: "",
  estaEditando: false,
  isDiaCorreto: true,
  isHorasCorreta: true,
  motivoConflito: "",
  openModalConflito: false,
  conflitos: [],
  deveCriarProximaAtividade: false,
  atividadeConcluida: false,
  naoCriarProximaAtividade: false,
  foiCriadaPorWorkflow: false,
  temProximaAtividadeEncadeada: false,
  atividadeConflitos: {
    title: "",
    text: "",
    isOpen: false,
    conflitos: [],
    callback: identity,
  },
  marcarComoEdicao: true,
};

class ModalAtividadeNewCont extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_VALUES,
      tiposAtividade: [],
      preVendedores: [],
      contatosLead: [],
    };

    this.axiosSource = null;
  }

  componentDidMount() {
    this.buscarTiposAtividade();
    this.buscarPreVendedores();

    this.getValoresIniciais();
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && this.props.open !== prevProps.open) {
      this.getValoresIniciais();
    }
  }

  onSubmit = () => {
    this.setState({ salvando: true }, this.validarCamposObrigatorios);
  };

  getValoresIniciais() {
    if (!this.state.deveCriarProximaAtividade && this.props.atividadeEdicao && !this.state.estaEditando) {
      this.setValoresIniciais(this.props.atividadeEdicao);
    }
    if (this.props.lead && !this.state.estaEditando) {
      this.setValoresIniciais({ lead: this.props.lead });
    }
    if (this.props.preVendedorId) this.setPreVendedor();
  }

  setPreVendedor() {
    this.setState({ preVendedorSelecionado: this.props.preVendedorId });
  }

  setValoresIniciais = valoresIniciais => {
    if (valoresIniciais.lead.id) {
      this.buscarContatosEResponsavelLead(valoresIniciais.lead.id);
      this.setState({
        textoPesquisaLead: valoresIniciais.lead.nome,
        dataSourceLead: [{ id: valoresIniciais.lead.id, nome: valoresIniciais.lead.nome }],
        lead: { id: valoresIniciais.lead.id, nome: valoresIniciais.lead.nome },
        horas: "",
      });
    }
    if (this.props.atividadeEdicao) {
      this.setState({
        preVendedorSelecionado: valoresIniciais.preVendedorId,
        tipoAtividadeSelecionadaId: valoresIniciais.tipoId,
        descricaoTipo: valoresIniciais.descricaoTipo,
        contatosSelecionados: Array.isArray(valoresIniciais.contatosObj)
          ? valoresIniciais.contatosObj.map(({ nome: descricao, ...rest }) => ({ ...rest, descricao }))
          : [],
        dia: valoresIniciais.dia,
        horas: valoresIniciais.horas,
        notificacao: valoresIniciais.notificacao || 0,
        observacoes: valoresIniciais.observacoes,
        atividadeConcluida: valoresIniciais.atividadeConcluida,
        naoCriarProximaAtividade: valoresIniciais.naoCriarProximaAtividade,
        foiCriadaPorWorkflow: valoresIniciais.foiCriadaPorWorkflow,
        temProximaAtividadeEncadeada: valoresIniciais.temProximaAtividadeEncadeada,
        estaEditando: valoresIniciais.marcarComoEdicao ?? true,
      });
    }
  };

  getConflitos = () => this.state.conflitos;

  getAtividadeParaSalvar = () => {
    const atividade = {
      id: this.state.deveCriarProximaAtividade ? "" : this.props?.atividadeEdicao?.id,
      notificacao: this.state.notificacao,
      observacoes: this.state.observacoes,
      contatos: this.state.contatosSelecionados && this.state.contatosSelecionados.map(({ id }) => id),
      leadid: this.state.lead.id,
      lead: this.state.lead,
      dia: this.state.dia,
      horas: this.state.horas,
      data: `${this.state.dia} ${this.state.horas}`,
      tipoId: this.state.tipoAtividadeSelecionadaId,
      preVendedorId: this.state.preVendedorSelecionado || this.props.preVendedorId,
      descricaoTipo: this.state.descricaoTipo,
      atividadeConcluida: this.state.atividadeConcluida,
      naoCriarProximaAtividade: this.state.naoCriarProximaAtividade,
    };
    return atividade;
  };

  getCampoAtividadeErrorText = listaTiposAtividade => {
    if (!this.state.salvando) {
      return "";
    }

    if (this.state.tipoAtividadeSelecionadaId === undefined || this.state.tipoAtividadeSelecionadaId < 1) {
      return this.props.intl.formatMessage({
        defaultMessage: "Campo obrigatório.",
      });
    }

    const atividadeSelecionada = listaTiposAtividade.find(({ id }) => id === this.state.tipoAtividadeSelecionadaId);
    if (atividadeSelecionada !== undefined && !atividadeSelecionada.ativo) {
      return this.props.intl.formatMessage({
        defaultMessage: "O tipo de atividade está inativo. Obrigatório selecionar um tipo ativo.",
      });
    }

    return "";
  };

  buscarPreVendedores = async () => {
    if (this.props.isGerente) {
      const tasks = { taskPreVendedores: await axios.get(`/api/pipeline/usuario1/listar?tipo=PreVendedor`) };
      const { taskPreVendedores } = objectKeysToCamelCase(tasks);
      this.setState({ preVendedores: taskPreVendedores.data });

      const isLeadReadOnly = !!this.props.lead?.preVendedorId;

      if (isLeadReadOnly) {
        this.setState({
          preVendedores: this.state.preVendedores.filter(pv => pv.id === this.props.lead.preVendedorId),
        });
      }
    }
  };
  buscarTiposAtividade = async () => {
    const tasks = { taskTiposAtividade: await axios.get("/api/pipeline/TipoAtividade/Listar?somenteAtivos=false") };
    const { taskTiposAtividade } = objectKeysToCamelCase(tasks);
    this.setState({
      tiposAtividade: taskTiposAtividade.data,
      tipoAtividadeOutros: taskTiposAtividade.data.find(f => f.nuTipoAtividade === 4).id,
    });
  };

  clearAll = () => {
    this.setState({ ...INITIAL_VALUES });
  };

  clearAllPreserveLead = () => {
    this.setState(
      {
        ...INITIAL_VALUES,
        preVendedorSelecionado: this.state.preVendedorSelecionado,
        dataSourceLead: this.state.dataSourceLead,
        lead: this.state.lead,
        leadId: this.state.leadId,
        deveCriarProximaAtividade: this.state.deveCriarProximaAtividade,
        textoPesquisaLead: this.state.textoPesquisaLead,
      },
      this.getValoresIniciais
    );
  };

  handleClose = () => {
    this.clearAll();
    this.props.onClose();
  };

  addConflitos = conflitos => {
    this.setState({
      conflitos,
    });
  };

  temConflitos = () => this.state.conflitos.length > 0;

  validarCamposObrigatorios = async () => {
    const { estaEditando } = this.state;
    const estaCriando = !estaEditando;
    const ati = this.getAtividadeParaSalvar();
    const now = Moment();
    const hoje = Moment().startOf("day");
    const [ano, mes, dia] = ati.dia.split("-");
    const [horas, minutos] = ati.horas.split(":");
    const conflitos = [];
    const dataHoraRecebida = Moment(new Date(ano, mes - 1, dia, horas, minutos));
    const diaRecebido = Moment(new Date(ano, mes - 1, dia)).startOf("day");

    this.setState({ salvando: true });

    const isHorasCorreta = !!ati.horas;
    this.setState({ isHorasCorreta });

    const isDiaCorreto = !!ati.dia;
    this.setState({ isDiaCorreto });

    const tipoAtividadeDetalhes = this.state.tiposAtividade.find(a => a.id === ati.tipoId);
    const tipoAtividadeOutrosPreenchido = ati.tipoId === this.state.tipoAtividadeOutros ? !!ati.descricaoTipo : true;

    const isBeforeToday = isDiaCorreto && diaRecebido.isBefore(hoje);
    const diaOk = estaCriando ? !isBeforeToday : true;

    const hasError = [
      ati.tipoId,
      tipoAtividadeDetalhes,
      tipoAtividadeDetalhes?.ativo,
      ati.preVendedorId,
      isHorasCorreta,
      isDiaCorreto,
      tipoAtividadeOutrosPreenchido,
      diaOk,
    ].some(val => !val);

    if (hasError) {
      return;
    }

    if (estaEditando && isBeforeToday) {
      conflitos.push({
        descricao: this.props.intl.formatMessage({ defaultMessage: "O dia informado é anterior ao dia atual." }),
        motivo: "dateIsBefore",
        manter: false,
      });
    }

    const isToday = isDiaCorreto && diaRecebido.diff(hoje) === 0;
    const isBeforeNow = !isBeforeToday && isToday && dataHoraRecebida.diff(now) < 0;

    if (isBeforeNow) {
      conflitos.push({
        descricao: this.props.intl.formatMessage({ defaultMessage: "A hora informada é anterior à hora atual." }),
        motivo: "hourIsBefore",
        manter: false,
      });
    }

    if (!ati.lead) {
      const conflito = {
        descricao: this.props.intl.formatMessage({ defaultMessage: "O Lead não foi informado." }),
        motivo: "lead",
        manter: false,
      };
      conflitos.push(conflito);
    }

    if (conflitos.length <= 0) {
      await this.validarConflitosHorario(ati);
    } else
      this.setState(
        { conflitos },
        this.confirmarConflitos(conflitos, () => this.validarConflitosHorario(ati), this.confirmarConflitos)
      );
  };

  validarConflitosHorario = async atividade => {
    try {
      AjaxBlackout.Show();
      const { data: conflitosHorario } = await axios.get("/api/pipeline/atividade/conflitoHorario", {
        params: {
          atividadeId: atividade.id || "",
          preVendedorId: atividade.preVendedorId,
          data: atividade.data,
        },
      });

      if (!conflitosHorario?.length) {
        await this.validarConflitosMesmoLead(atividade);
        return;
      }

      this.openAtividadeConflitos({
        title: "Conflitos de Horário",
        text: this.props.intl.formatMessage({
          defaultMessage: "As seguintes atividades estão agendadas para o mesmo horário:",
        }),
        conflitos: conflitosHorario,
        callback: accepted => {
          this.closeAtividadeConflitos();
          if (accepted) {
            this.validarConflitosMesmoLead(atividade);
          }
        },
      });
    } catch (err) {
      createSnackBar("Houve um erro ao validar conflitos de horários.");
      // Permite salvar
      await this.handleSalvar(atividade);
      // eslint-disable-next-line
      console.error(err);
    } finally {
      AjaxBlackout.Hide();
    }
  };

  validarConflitosMesmoLead = async atividade => {
    if (!atividade?.lead?.id) {
      await this.handleSalvar(atividade);
      return;
    }

    try {
      AjaxBlackout.Show();
      const { data: conflitosMesmoLead } = await axios.get("/api/pipeline/atividade/NaoConcluidas", {
        params: {
          leadId: atividade.lead.id,
          atividadeId: atividade.id || "",
        },
      });

      if (!conflitosMesmoLead?.length) {
        await this.handleSalvar(atividade);
        return;
      }

      this.openAtividadeConflitos({
        title: "Alerta",
        text: this.props.intl.formatMessage({ defaultMessage: "Já existem atividades agendadas para o Lead:" }),
        conflitos: conflitosMesmoLead,
        callback: accepted => {
          this.closeAtividadeConflitos();
          if (accepted) {
            this.handleSalvar(atividade);
          }
        },
      });
    } catch (err) {
      createSnackBar("Houve um erro ao validar atividades não concluídas para o Lead.");
      // Permite salvar
      await this.handleSalvar(atividade);
      // eslint-disable-next-line
      console.error(err);
    } finally {
      AjaxBlackout.Hide();
    }
  };

  openAtividadeConflitos = ({ title, text, conflitos, callback }) =>
    this.setState({
      atividadeConflitos: {
        isOpen: true,
        title,
        text,
        conflitos,
        callback,
      },
    });

  closeAtividadeConflitos = () => this.setState({ atividadeConflitos: { ...INITIAL_VALUES.atividadeConflitos } });

  confirmarConflitos = (conflitos, callback, andale) => {
    const getProximoConflito = () => {
      andale(conflitos.slice(1), callback, andale);
    };

    const dialogCallback = conflitos.length > 1 ? getProximoConflito : callback;

    setTimeout(() =>
      createDialogConfirm({
        title: "Alerta",
        text: conflitos[0].descricao,
        acceptLabel: this.props.intl.formatMessage({ defaultMessage: "Manter assim mesmo" }),
        callback: accepted => {
          if (accepted) {
            dialogCallback();
          }
        },
        dialogProps: {
          maxWidth: "sm",
          fullWidth: true,
        },
      })
    );
  };

  handleSalvar = async atividade => {
    let success = false;

    try {
      AjaxBlackout.Show();

      const { data } = await axios.post("/api/pipeline/atividade/salvar", atividade);
      success = data;

      if (!success) {
        throw Error("Erro ao salvar atividade.");
      }
    } catch (err) {
      createSnackBar(err.message);
      return;
    } finally {
      AjaxBlackout.Hide();
    }

    if (!this.state.atividadeConcluida) {
      this.clearAll();
      this.props.onClose();
    }

    if (this.state.atividadeConcluida) {
      this.setState({ deveCriarProximaAtividade: true }, this.clearAllPreserveLead);
    }

    maybeCallback(this.props.callback)(success);
    createSnackBar(this.props.intl.formatMessage({ defaultMessage: "Atividade salva com sucesso" }));
  };

  handleUpdateIsSearchLead = status => {
    this.setState({ isSearchLead: status });
  };

  handleChangeLead = chosenRequest => {
    this.setState({ lead: chosenRequest });
  };

  buscarContatosEResponsavelLead = async leadId => {
    let resp = await axios.get("/Api/Pipeline/Atividade/RetornaContatosEResponsavelLead", { params: { leadId } });
    resp = objectKeysToCamelCase(resp);
    this.setState({
      contatosLead: resp.data.contatos,
      preVendedorSelecionado: resp.data.responsavel.id,
    });
  };

  autocompleteLead = debounce(async value => {
    this.setState({ contatosSelecionados: [] });

    this.handleUpdateIsSearchLead(true);

    if (this.axiosSource) {
      this.axiosSource.cancel();
    }

    const cancelToken = axios.CancelToken;
    this.axiosSource = cancelToken.source();
    const resp = await axios.get(`/Api/Pipeline/Lead/AutocompleteLead`, {
      cancelToken: this.axiosSource.token,
      params: {
        textoPesquisaLead: value,
        responsavelId: this.state.preVendedorSelecionado === 0 ? null : this.state.preVendedorSelecionado,
      },
    });
    this.setState({ dataSourceLead: objectKeysToCamelCase(resp.data) });
    this.handleUpdateIsSearchLead(false);
  }, 500);

  handleOnUpdateInputEmpresa = value => {
    this.handleChangeLead("");
    this.setState({ textoPesquisaLead: value });
    if (value) {
      this.autocompleteLead(value);
    } else {
      this.setState({ contatosSelecionados: [] });
    }
  };

  handleOnNewRequestEmpresa = async chosenRequest => {
    this.handleChangeLead(chosenRequest);
    await this.buscarContatosEResponsavelLead(chosenRequest.id);
  };

  handleOnRequestAddContato = contato => {
    if (this.state.contatosLead.filter(c => c.id === contato.id).length > 0) {
      this.setState({ contatosSelecionados: [...this.state.contatosSelecionados, contato] });
    }
  };

  handleOnRequestDeleteContato = contatoExId => {
    const contatos = this.state.contatosSelecionados;
    const selecionados = contatos.filter(contato => contato.id !== contatoExId);
    this.setState({ contatosSelecionados: selecionados });
  };

  handleChangeTempoNotificacao = event => {
    this.setState({ notificacao: event.target.value });
  };

  handleChangeTipoAtividadeSelecionada = event => {
    this.buscarTiposAtividade();
    this.setState({ tipoAtividadeSelecionadaId: event.target.value, descricaoTipo: "" });
  };

  handleChangeVendedor = event => {
    const { value } = event.target;
    if (value !== this.state.preVendedorSelecionado) {
      this.setState({
        preVendedorSelecionado: value,
        dataSourceLead: [],
        contatosSelecionados: [],
        lead: "",
        textoPesquisaLead: "",
      });
    }
  };

  handleChangeDia = event => {
    this.setState({ dia: event.target.value });
  };

  handleChangeHoras = event => {
    this.setState({ isHorasCorreta: true, horas: event.target.value });
  };

  handleChangeObservacoes = event => {
    this.setState({ observacoes: event.target.value });
  };

  handleChangeDescricaoTipo = event => {
    this.setState({ descricaoTipo: event.target.value });
  };

  handlecheckedFlConcluirAtividade = () => {
    if (!this.state.atividadeConcluida) {
      this.setState({ atividadeConcluida: true });
    } else {
      this.setState({ atividadeConcluida: false });
    }
  };

  handlecheckedFlNaoCriarProximaAtividade = () => {
    this.setState({ naoCriarProximaAtividade: !this.naoCriarProximaAtividade });
  };

  handlers = () => ({
    onSubmit: this.onSubmit,
    handleClose: this.handleClose,
    handleChangeDia: this.handleChangeDia,
    atividadeConcluida: this.atividadeConcluida,
    handleChangeHoras: this.handleChangeHoras,
    handleChangeObservacoes: this.handleChangeObservacoes,
    handleChangeDescricaoTipo: this.handleChangeDescricaoTipo,
    handleChangeTipoAtividadeSelecionada: this.handleChangeTipoAtividadeSelecionada,
    handleChangeTempoNotificacao: this.handleChangeTempoNotificacao,
    handleOnUpdateInputEmpresa: this.handleOnUpdateInputEmpresa,
    handleOnNewRequestEmpresa: this.handleOnNewRequestEmpresa,
    handleOnRequestAddContato: this.handleOnRequestAddContato,
    handleOnRequestDeleteContato: this.handleOnRequestDeleteContato,
    handleChangeVendedor: this.handleChangeVendedor,
    handlecheckedFlConcluirAtividade: this.handlecheckedFlConcluirAtividade,
    handlecheckedFlNaoCriarProximaAtividade: this.handlecheckedFlNaoCriarProximaAtividade,
    getCampoAtividadeErrorText: this.getCampoAtividadeErrorText,
  });

  render() {
    if (this.props.isLoading) {
      AjaxBlackout.Show();
      return null;
    }
    AjaxBlackout.Hide();

    return (
      <Fragment>
        <ModalConflito {...this.state.atividadeConflitos} />
        <ModalAtividadeNew
          title={
            this.state.estaEditando
              ? this.props.intl.formatMessage({ defaultMessage: "Editar Atividade" })
              : this.props.intl.formatMessage({ defaultMessage: "Criar Atividade" })
          }
          estaEditando={this.state.estaEditando}
          open={this.props.open || this.state.deveCriarProximaAtividade}
          handlers={this.handlers()}
          dataSourceLead={this.state.dataSourceLead}
          isGerente={this.props.isGerente}
          tiposAtividade={this.state.tiposAtividade}
          preVendedores={this.state.preVendedores}
          contatosLead={this.state.contatosLead}
          textoPesquisaLead={this.state.textoPesquisaLead}
          contatosSelecionados={this.state.contatosSelecionados}
          isSearchLead={this.state.isSearchLead}
          leadReadOnly={!!this.props.lead}
          lead={this.state.lead}
          leadId={this.state.leadId}
          notificacao={this.state.notificacao}
          preVendedorSelecionado={this.state.preVendedorSelecionado}
          salvando={this.state.salvando}
          dia={this.state.dia}
          horas={this.state.horas}
          tipoAtividadeSelecionadaId={this.state.tipoAtividadeSelecionadaId}
          descricaoTipo={this.state.descricaoTipo}
          tipoAtividadeOutros={this.state.tipoAtividadeOutros}
          isDiaCorreto={this.state.isDiaCorreto}
          observacoes={this.state.observacoes}
          atividadeConcluida={this.state.atividadeConcluida}
          naoCriarProximaAtividade={this.state.naoCriarProximaAtividade}
          foiCriadaPorWorkflow={this.state.foiCriadaPorWorkflow}
          temProximaAtividadeEncadeada={this.state.temProximaAtividadeEncadeada}
          isHorasCorreta={this.state.isHorasCorreta}
          motivoConflito={this.state.motivoConflito}
          openModalConflito={this.state.openModalConflito}
          atividade={this.getAtividadeParaSalvar()}
          conflitos={this.state.conflitos}
          mostrarMensagemSucesso={this.props.mostrarMensagemSucesso || this.state.deveCriarProximaAtividade}
        />
      </Fragment>
    );
  }
}

ModalAtividadeNewCont.propTypes = {
  open: PropTypes.bool,
  callback: PropTypes.func,
  isGerente: PropTypes.bool,
  atividadeEdicao: PropTypes.object,
  isLoading: PropTypes.bool,
  mostrarMensagemSucesso: PropTypes.bool,
  lead: PropTypes.object,
  preVendedorId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  intl: PropTypes.object,
};

export default injectIntl(ModalAtividadeNewCont);
