import React, { Fragment } from "react";
import { PropTypes } from "prop-types";
import { useIntl } from "react-intl";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";

function ModalExcluirAtividade({ cancelar, excluir }) {
  const intl = useIntl();
  return (
    <Fragment>
      <DialogTitle>{intl.formatMessage({ defaultMessage: "Não será possível recuperar essa atividade" })}</DialogTitle>
      <DialogContent>
        <DialogContentText>{intl.formatMessage({ defaultMessage: "Deseja realmente excluí-la?" })}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelar}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
        <Button color="primary" onClick={excluir}>
          {intl.formatMessage({ defaultMessage: "Excluir" })}
        </Button>
      </DialogActions>
    </Fragment>
  );
}

ModalExcluirAtividade.propTypes = {
  cancelar: PropTypes.func,
  excluir: PropTypes.func,
};
export default ModalExcluirAtividade;
