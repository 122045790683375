import moment from "moment";
import { useIntl } from "react-intl";

export default function getTooltipAtividade(atividade) {
  const intl = useIntl();
  const data = moment(atividade.data);
  const jaPassou = moment().isAfter(data);
  return `${!jaPassou ? intl.formatMessage({ defaultMessage: "em" }) : ""} ${data.toNow(true)} ${
    jaPassou ? intl.formatMessage({ defaultMessage: "em atraso" }) : ""
  }`;
}
