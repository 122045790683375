import { useState } from "react";
import axios from "axios";
import { useIntl } from "react-intl";

import { useUsuario } from "../../../../hooks";
import { AjaxBlackout, createSnackbar, createSnackbarAPIException } from "../../../../_common";
import { maybeCallback } from "../../../../_common/utils/fp";
import { TIPO_ACAO } from "../../../workflow/constantes";

export default function useConcluirAtividade(callbackConcluir) {
  const intl = useIntl();
  const [currentLead, setCurrentLead] = useState(null);
  const [currentPreVendedorId, setCurrentPreVendedorId] = useState(0);
  const [open, setOpen] = useState(false);
  const { isGerente, featureToggles } = useUsuario();

  const verificaAcaoEncadeadaWF = async ({ automacaoAcaoAtividadeId, automacaoConfigEventoId }) => {
    if (featureToggles.encerrarWfConclusaoAtividade && automacaoAcaoAtividadeId && automacaoAcaoAtividadeId > 0) {
      const { data } = await axios.get(
        `/api/pipeline/workflow/buscarProximaAcaoEncadeada?workflowId=${automacaoConfigEventoId}&acaoId=${automacaoAcaoAtividadeId}`
      );

      if (data) {
        let descricao;
        let icone;
        switch (data.workflowAcaoDto.tipoAcao) {
          case TIPO_ACAO.ENVIO_HSM:
            descricao = data.workflowAcaoDto.nomeTemplateMensagemHsm;
            icone = "whatsapp";
            break;
          case TIPO_ACAO.CADENCIA:
            descricao = data.workflowAcaoDto.nomeCampanha;
            icone = "send";
            break;
          default:
            descricao = data.tipoAtividadeDto.descricao;
            icone = data.tipoAtividadeDto.icone;
            break;
        }

        return {
          icone,
          descricao,
        };
      }
    }

    return null;
  };

  const concluirAtividade = async ({ id, lead, preVendedorId }, encerrarWfEncadeado = false) => {
    let success = false;

    if (isGerente) {
      createSnackbar(intl.formatMessage({ defaultMessage: "Ação não permitida." }));
      return success;
    }

    setCurrentLead(lead);
    setCurrentPreVendedorId(preVendedorId);
    AjaxBlackout.Show();
    try {
      const { data } = await axios.post(
        `/api/pipeline/atividade/concluir?id=${id}&encerrarWfEncadeado=${encerrarWfEncadeado}`
      );
      success = data;

      if (success) {
        createSnackbar(intl.formatMessage({ defaultMessage: "Atividade concluída com sucesso!" }));
        maybeCallback(callbackConcluir)(success, { lead, atividadeId: id });

        if (encerrarWfEncadeado === false) setOpen(true);
      } else {
        createSnackbar(intl.formatMessage({ defaultMessage: "Atividade já foi concluída anteriormente!" }));
      }

      document.dispatchEvent(new Event("atividades/lista/reload"));
      document.dispatchEvent(new Event("dashboard/atividades/reload"));
      document.dispatchEvent(new Event("detalhe-lead/timeline/reload"));
      document.dispatchEvent(new Event("atividadesNavbar/lista/reload"));
    } catch (err) {
      createSnackbarAPIException(err);
    } finally {
      AjaxBlackout.Hide();
    }
    return success;
  };

  const onClose = () => {
    setOpen(false);
    setCurrentLead(null);
  };

  return {
    concluirAtividade,
    modalConcluirAtividadeProps: {
      open,
      onClose,
      isGerente,
      mostrarMensagemSucesso: true,
      lead: currentLead,
      preVendedorId: currentPreVendedorId,
    },
    verificaAcaoEncadeadaWF,
  };
}
