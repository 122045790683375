import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles, Avatar, Icon } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import { Link } from "react-router-dom";
import permissions from "../../../_common/permissions";
import Chip from "../../../components/Chip";
import { Tipografia, Flex } from "../../../_common";
import { getTooltipAtividade, getLabelAtividade } from "../../atividade/utils";
import { ExcluirAtividade } from "../../atividade/components";
import { EditarAtividadeButton } from "../../atividade/components/EditarAtividade";
import { ConcluirAtividadeButton } from "../../atividade/components/ConcluirAtividade";
import { TIPOS_AGRUPAMENTO } from "../../../store/reducers/atividades/atividades";
import { useUsuario, useSelectPermission } from "../../../hooks";
import MenuAcoesRapidas from "../../atividade/components/MenuAcoesRapidas";
import { ACCESS_LEVEL } from "../../../_common/constantes";

function Atividades({
  atividades,
  tipoAgrupamento,
  onClickEditarAtividade,
  toggleModalLigacao,
  onClickConcluirAtividade,
  eventReloadName,
  classes,
  ocultarDadosNavbar = false,
  linkLeadNavbar = false,
}) {
  const { id: userId, isGerente } = useUsuario();
  const permissaoAtividade = useSelectPermission(permissions.MODULOATIVIDADES);

  const detalhesLeadAtividadesNavbar = leadId => {
    window.open(`/spotter/detalhes-lead/${leadId}`, `_blank`);
  };

  return (
    <>
      <Flex flexDirection="column" justifyContent="space-between">
        {atividades.map(atividade => (
          <>
            <Flex key={atividade.id} justifyContent="space-between" alignItems="center" className={classes.content}>
              <Flex
                justifyContent="flex-start"
                alignItems="center"
                style={{ justifySelf: "flex-start", width: "28%", marginRight: 20 }}
              >
                <Avatar className={classes.avatar}>
                  <Icon>{atividade.icone}</Icon>
                </Avatar>

                <Flex flexDirection="column">
                  <Tipografia tipo="textoSidebar">{atividade.tipo}</Tipografia>
                  <Flex alignItems="center">
                    <Chip
                      tooltip={getTooltipAtividade(atividade)}
                      label={getLabelAtividade(atividade, tipoAgrupamento)}
                      status={atividade.status}
                    />
                    {atividade.status === 4 && <Check className={classes.check} />}
                  </Flex>
                </Flex>
              </Flex>

              <Flex flexDirection="column" style={{ width: "23%", marginRight: 20 }}>
                {atividade.lead && (
                  <>
                    {!linkLeadNavbar && (
                      <Link to={`/spotter/detalhes-lead/${atividade.lead.id}`}>
                        <Tipografia tipo="textoSidebar" style={{ marginRight: 20 }}>
                          {atividade.lead.nome}
                        </Tipografia>
                      </Link>
                    )}
                    {linkLeadNavbar && (
                      <Tipografia
                        tipo="textoSidebar"
                        style={{ marginRight: 20, cursor: "pointer" }}
                        onClick={() => detalhesLeadAtividadesNavbar(atividade.lead.id)}
                      >
                        {atividade.lead.nome}
                      </Tipografia>
                    )}
                  </>
                )}
                {atividade.contatos?.map(contato => (
                  <Tipografia key={contato.id} tipo="notaLegendas" cor="darkSecondaryText">
                    {contato.nome}
                    {contato.telefone ? `: ${contato.telefone}` : ""}
                  </Tipografia>
                ))}
              </Flex>

              {!ocultarDadosNavbar && (
                <>
                  <Flex style={{ marginRight: 20, width: "10%" }}>
                    {atividade.etapa && (
                      <Tipografia tipo="textoSidebar" cor="darkSecondaryText" style={{ marginRight: 20 }}>
                        {`${atividade?.etapa?.funil?.nome} - ${atividade?.etapa?.nome}`}
                      </Tipografia>
                    )}
                  </Flex>

                  <Flex style={{ marginRight: 20, width: "30%" }}>
                    <Tipografia tipo="textoSidebar" cor="darkSecondaryText">
                      {atividade.preVendedor}
                    </Tipografia>
                  </Flex>

                  <Flex style={{ marginRight: 20, width: "30%" }}>
                    <Tipografia tipo="textoSidebar" cor="darkSecondaryText">
                      {atividade.observacoes}
                    </Tipografia>
                  </Flex>
                </>
              )}

              {(atividade.preVendedorId === userId ||
                isGerente ||
                (permissaoAtividade &&
                  (permissaoAtividade.nivelAcesso === ACCESS_LEVEL.COMPLETE ||
                    permissaoAtividade.nivelAcesso === ACCESS_LEVEL.GROUP))) && (
                <Flex justifyContent="flex-end" className={classes.botoes}>
                  <ExcluirAtividade
                    id={atividade.id}
                    callback={success => success && document.dispatchEvent(new Event(eventReloadName))}
                  />
                  {atividade.status !== 4 && (
                    <Fragment>
                      <EditarAtividadeButton onClick={() => onClickEditarAtividade(atividade.id)} />
                      {!isGerente && <ConcluirAtividadeButton onClick={() => onClickConcluirAtividade(atividade)} />}
                    </Fragment>
                  )}
                  <MenuAcoesRapidas
                    atividade={atividade}
                    setModalLigacaoIsOpen={isOpen => {
                      toggleModalLigacao(atividade, isOpen);
                    }}
                  />
                </Flex>
              )}
            </Flex>
          </>
        ))}
      </Flex>
    </>
  );
}

Atividades.propTypes = {
  atividades: PropTypes.array,
  tipoAgrupamento: PropTypes.oneOf([TIPOS_AGRUPAMENTO.DIA, TIPOS_AGRUPAMENTO.MES, TIPOS_AGRUPAMENTO.ANO]),
  onClickEditarAtividade: PropTypes.func,
  toggleModalLigacao: PropTypes.func,
  onClickConcluirAtividade: PropTypes.func,
  eventReloadName: PropTypes.string,
  classes: PropTypes.object,
  ocultarDadosNavbar: PropTypes.bool,
  linkLeadNavbar: PropTypes.bool,
};

export default withStyles(theme => ({
  content: {
    paddingLeft: 20,
    paddingRight: 20,
    minHeight: 56,
    paddingTop: 8,
    paddingBottom: 8,
    "&:hover": { backgroundColor: theme.palette.backgroundRowHover },
    "&:hover $botoes": { visibility: "visible" },
  },
  check: {
    color: theme.palette.status.green,
    fontSize: 18,
  },
  botoes: {
    visibility: "hidden",
    justifySelf: "flex-end",
    backgroundColor: theme.palette.backgroundRowHover,
  },
  avatar: {
    marginRight: 24,
  },
}))(Atividades);
