import { useState } from "react";
import axios from "axios";
import Moment from "moment";
import { useIntl } from "react-intl";
import { useUsuario } from "../../../../hooks";
import { createSnackbarAPIException, createSnackbar, AjaxBlackout } from "../../../../_common";

export default function useEditarAtividade() {
  const [open, setOpen] = useState(false);
  const [atividadeEdicao, setAtividadeEdicao] = useState();
  const [isLoading, setIsloading] = useState(false);
  const { isGerente } = useUsuario();
  const intl = useIntl();

  const editarAtividade = async id => {
    try {
      AjaxBlackout.Show();
      setIsloading(true);
      let atividade = {};
      const resp = await axios.get(`/api/pipeline/atividade/buscar?id=${id}`);
      if (resp.data) {
        if (resp.data.atividadeConcluida) {
          createSnackbar(intl.formatMessage({ defaultMessage: "Atividade já foi concluída anteriormente!" }));
          document.dispatchEvent(new Event("atividades/lista/reload"));
          document.dispatchEvent(new Event("dashboard/atividades/reload"));
          document.dispatchEvent(new Event("detalhe-lead/timeline/reload"));
        } else {
          atividade = {
            ...resp.data,
            dia: Moment(resp.data.data).format("YYYY-MM-DD"),
            horas: Moment(resp.data.data).format("HH:mm"),
          };
          setAtividadeEdicao(atividade);
          if (atividade) setOpen(true);
        }
      }
    } catch (err) {
      createSnackbarAPIException(err);
    } finally {
      setIsloading(false);
      AjaxBlackout.Hide();
    }
  };

  const onClose = () => {
    setAtividadeEdicao();
    setOpen(false);
  };

  return {
    editarAtividade,
    modalEditarAtividadeProps: {
      open,
      onClose,
      isGerente,
      atividadeEdicao,
      isLoading,
    },
  };
}
