import React, { Fragment, useState } from "react";
import { PropTypes } from "prop-types";
import { useIntl } from "react-intl";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";

import ModalAtividadeNewCont from "./ModalAtividadeNewCont";
import { useUsuario, useSelectPermission } from "../../../hooks";
import { ActionButton } from "../../../components";
import PERMISSIONS from "../../../_common/permissions";
import { ACCESS_LEVEL } from "../../../_common/constantes";

function CriarAtividade({ children, callBack, lead, ...props }) {
  const [openModal, setOpenModal] = useState(false);
  const { isGerente } = useUsuario();
  const permissaoAtividade = useSelectPermission(PERMISSIONS.MODULOATIVIDADES);

  const callBackCriar = status => {
    if (status) {
      document.dispatchEvent(new Event("atividades/lista/reload"));
    }

    if (callBack) callBack(status);
  };
  const intl = useIntl();
  return (
    <Fragment>
      {permissaoAtividade &&
        permissaoAtividade.nivelAcesso !== ACCESS_LEVEL.NONE && (
          <ActionButton
            id="btn-criar-atividade"
            type="icon"
            tooltip={intl.formatMessage({ defaultMessage: "Criar Atividade" })}
            label={intl.formatMessage({ defaultMessage: "Criar Atividade" })}
            onClick={() => setOpenModal(true)}
            icon={<AssignmentTurnedIn />}
            leads={lead}
            {...props}
          />
        )}
      {permissaoAtividade &&
        permissaoAtividade.nivelAcesso !== ACCESS_LEVEL.NONE &&
        openModal && (
          <ModalAtividadeNewCont
            open={openModal}
            callback={callBackCriar}
            onClose={() => setOpenModal(false)}
            isGerente={isGerente}
            lead={lead && lead}
            {...props}
          />
        )}
    </Fragment>
  );
}

CriarAtividade.propTypes = {
  callBack: PropTypes.func,
  children: PropTypes.func,
  lead: PropTypes.object,
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export default CriarAtividade;
