import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";

import { useUsuario } from "../../../../hooks";
import { usuarioPossuiTelefoniaIntegrada } from "../../../../store/reducers/usuario/selectors";
import { NU_TIPO_ATIVIDADE_ID } from "../../../../_common/constantes";
import { ActionButton } from "../../../../components";

function AtividadeRetornarLigacao({
  atividade = {},
  styleIcon,
  useIconMenu,
  ignoreValidationActivityType,
  alwaysShow,
  setModalLigacaoIsOpen,
}) {
  const intl = useIntl();
  const { isGerente } = useUsuario();
  const possuiTelefoniaIntegrada = useSelector(usuarioPossuiTelefoniaIntegrada);
  const isRetornoLigacao = ignoreValidationActivityType
    ? true
    : atividade.nuTipoId === NU_TIPO_ATIVIDADE_ID.RETORNO_LIGACAO;
  const isConcluida = atividade.status === 4;
  const leadId = atividade?.lead?.id;

  const showButton = alwaysShow ? true : !!atividade && isRetornoLigacao && !isGerente && !isConcluida && leadId;

  if (!showButton && !useIconMenu) {
    return null;
  }

  const onClick = useCallback(() => {
    if (!possuiTelefoniaIntegrada) {
      window.open("https://exactsal.es/lp-wp-spotter-v2");
      return;
    }
    setModalLigacaoIsOpen(true);
  });

  return (
    <>
      {useIconMenu ? (
        <ActionButton
          id="btn-retornar-ligacao-atividade"
          type="menu"
          onClick={onClick}
          icon={<PhoneIcon />}
          label={intl.formatMessage({ defaultMessage: "Ligar" })}
          disabled={!showButton || !atividade.lead}
        />
      ) : (
        <IconButton onClick={onClick}>
          <PhoneIcon style={styleIcon} />
        </IconButton>
      )}
    </>
  );
}

AtividadeRetornarLigacao.propTypes = {
  atividade: PropTypes.shape({
    nuTipoId: PropTypes.number.isRequired,
    status: PropTypes.number,
    lead: PropTypes.shape({
      id: PropTypes.number,
      nome: PropTypes.string,
    }),
  }),
  styleIcon: PropTypes.object,
  useIconMenu: PropTypes.bool,
  ignoreValidationActivityType: PropTypes.bool,
  alwaysShow: PropTypes.bool,
  setModalLigacaoIsOpen: PropTypes.func,
};

export default AtividadeRetornarLigacao;
