import React from "react";
import { PropTypes } from "prop-types";

import EditarAtividadeButton from "./Button";
import useEditarAtividade from "./useEditarAtividade";
import ModalAtividadeNewCont from "../ModalAtividadeNewCont";

function EditarAtividade({ id, callback, styleIcon }) {
  const { editarAtividade, modalEditarAtividadeProps } = useEditarAtividade();

  return (
    <>
      <EditarAtividadeButton onClick={() => editarAtividade(id)} styleIcon={styleIcon} />
      {modalEditarAtividadeProps.open && <ModalAtividadeNewCont {...modalEditarAtividadeProps} callback={callback} />}
    </>
  );
}

EditarAtividade.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callback: PropTypes.func,
  styleIcon: PropTypes.object,
};

export default EditarAtividade;
