import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { ActionButton } from "../../../../components";

EditarAtividadeButton.propTypes = {
  styleIcon: PropTypes.object,
  useIconWithLabel: PropTypes.bool,
  onClick: PropTypes.func,
};

function EditarAtividadeButton({ styleIcon, useIconWithLabel = false, ...props }) {
  const intl = useIntl();

  return (
    <>
      {useIconWithLabel ? (
        <ActionButton
          id="btn-editar-atividade"
          type="menu"
          onClick={() => props.onClick()}
          icon={<EditIcon />}
          label={intl.formatMessage({ defaultMessage: "Editar" })}
        />
      ) : (
        <IconButton {...props}>
          <EditIcon style={styleIcon} />
        </IconButton>
      )}
    </>
  );
}

export default EditarAtividadeButton;
