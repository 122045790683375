import { useIntl } from "react-intl";
import { TIPOS_AGRUPAMENTO } from "../../../store/reducers/atividades/atividades";

export default function getLabelAtividade(atividade, tipoAgrupamento) {
  const intl = useIntl();

  if (tipoAgrupamento === TIPOS_AGRUPAMENTO.ANO) {
    return intl.formatDate(atividade.data, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      weekday: "short",
    });
  }
  return intl.formatTime(atividade.data, {
    hour: "numeric",
    minute: "numeric",
  });
}
