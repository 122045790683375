import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import Moment from "moment";
import { injectIntl, FormattedMessage } from "react-intl";
import { AutoComplete, LinearProgress, Chip, Avatar, Checkbox } from "material-ui";
import {
  Button,
  CircularProgress,
  Fade,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  FormHelperText,
} from "@material-ui/core";
import {
  ActionAssignmentTurnedIn,
  ActionDateRange,
  DeviceAccessTime,
  CommunicationBusiness,
  SocialNotifications,
  ActionPermContactCalendar,
  ActionAccountCircle,
  ActionSpeakerNotes,
  NotificationEventNote,
  ActionDone,
} from "material-ui/svg-icons";
import ChipInput from "material-ui-chip-input";
import useSWR from "swr";
import Axios from "axios";

import { Flex, Modal, SelectScrollableMUI, Tipografia } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet/injectSheet";
import AsyncSelect from "../../../components/AsyncSelect";
import { useTipoPlanoSpotter } from "../../../hooks";

const corIconeInput = "#666666";

const styles = {
  divIconeInput: { paddingRight: 8 },
  divInput: { display: "flex", alignItems: "center", width: "100%" },
  divInputObservacoes: { display: "flex", alignItems: "flex-start", width: "100%" },
  divUmaColuna: { width: "50%", paddingRight: 16 },
  formStyle: { padding: 0 },
  "@global input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": { "-webkit-appearance": "none" },
  barraProgresso: {
    height: "2px !important",
    top: ({ lead, textoPesquisaLead }) => (Object.keys(lead).length === 0 && textoPesquisaLead !== "" ? -38 : -10),
    transform: ({ isSearchLead }) => `scaleX(${isSearchLead ? 1 : 0})`,
  },
  divMensagemSucesso: {
    paddingTop: 16,
  },
  divCheckConcluirAtividade: { padding: [16, 0], width: "50%" },
  divCheckNaoCriarProximaAtividade: { padding: [16, 0], width: "100%" },
  errorStyle: { color: "red" },
  empresaAutoComplete: { marginTop: -6 },
};

const validateDia = (val, isDiaCorreto, estaEditando, intl) => {
  if (!isDiaCorreto) {
    return intl.formatMessage({ defaultMessage: "Campo obrigatório." });
  }
  if (!estaEditando && Moment(val).isBefore(Moment(), "day")) {
    return intl.formatMessage(
      {
        defaultMessage: "Deve ser {data} ou posterior.",
      },
      { data: new Moment().format("DD/MM/YYYY") }
    );
  }
  return "";
};

const getStringIntl = (value, intl) => {
  const isIntlMessage = typeof value === "object" && !!value.defaultMessage;
  return isIntlMessage ? intl.formatMessage(value) : value;
};

const ModalAtividadeNew = ({
  open,
  title,
  handlers,
  preVendedores,
  lead,
  contatosLead,
  contatosSelecionados,
  isGerente,
  dataSourceLead,
  textoPesquisaLead,
  tipoAtividadeSelecionadaId,
  mostrarMensagemSucesso,
  mensagemAtividade,
  classes,
  notificacao,
  preVendedorSelecionado,
  salvando,
  dia,
  horas,
  descricaoTipo,
  tipoAtividadeOutros,
  observacoes,
  atividadeConcluida,
  naoCriarProximaAtividade,
  foiCriadaPorWorkflow,
  temProximaAtividadeEncadeada,
  isDiaCorreto,
  isHorasCorreta,
  leadReadOnly,
  estaEditando,
  intl,
  ...props
}) => {
  const isSpotterFull = useTipoPlanoSpotter();
  const diaError = salvando ? validateDia(dia, isDiaCorreto, estaEditando, intl) : "";
  const horasError = salvando && !isHorasCorreta ? intl.formatMessage({ defaultMessage: "Campo obrigatório." }) : "";
  const empresaError =
    Object.keys(lead).length === 0 && textoPesquisaLead !== ""
      ? intl.formatMessage({ defaultMessage: "Escolha um lead válido." })
      : "";
  const pvError =
    salvando && preVendedorSelecionado < 1 ? intl.formatMessage({ defaultMessage: "Campo obrigatório." }) : "";

  const { data: listaTiposAtividade, isValidating, mutate } = useSWR(
    "/api/pipeline/TipoAtividade/Listar",
    async url => {
      const { data } = await Axios.get(url);
      return data;
    }
  );

  return (
    <Modal
      title={title}
      open={open}
      tamanho="620px"
      mostraBotaoFechar
      onClose={handlers.handleClose}
      PaperProps={{ style: { width: 620, maxWidth: 620 } }}
      disableBackdropClick
    >
      {mostrarMensagemSucesso && (
        <Fade in={mostrarMensagemSucesso} timeout={1200}>
          <Flex alignItems="center" className={classes.divMensagemSucesso}>
            {!mensagemAtividade && <ActionDone color="#0F9D58" />}
            <Tipografia tipo="navbar">
              {getStringIntl(mensagemAtividade, intl) ||
                intl.formatMessage({
                  defaultMessage: "Atividade concluída! Qual será a próxima atividade do lead?",
                })}
            </Tipografia>
          </Flex>
        </Fade>
      )}
      <form id="formModalAtividade" className={classes.formStyle} onSubmit={handlers.onSubmit} noValidate>
        <Flex className={classes.divInput}>
          <Flex className={classes.divIconeInput}>
            <ActionAssignmentTurnedIn color={corIconeInput} />
          </Flex>
          <AsyncSelect
            id="tipoAtividadeId"
            name="tipoAtividadeId"
            label={intl.formatMessage({
              defaultMessage: "Tipo de atividade *",
            })}
            options={listaTiposAtividade}
            value={tipoAtividadeSelecionadaId}
            onChange={handlers.handleChangeTipoAtividadeSelecionada}
            enableSearch
            criarNovo={isGerente && isSpotterFull}
            urlCriarNovo={`/spotter/atividades/tipo-atividade`}
            fullWidth
            funcaoRecarregar={mutate}
            carregarSomenteAoAbrir
            loading={isValidating}
            error={!!handlers.getCampoAtividadeErrorText(listaTiposAtividade)}
            helperText={handlers.getCampoAtividadeErrorText(listaTiposAtividade)}
            {...props}
          />
        </Flex>
        {tipoAtividadeSelecionadaId === tipoAtividadeOutros && (
          <Flex>
            <Flex className={classes.divInput}>
              <div className={classes.divIconeInput}>
                <NotificationEventNote color={corIconeInput} />
              </div>
              <TextField
                name="descricaoTipo"
                id="descricaoTipo"
                required
                fullWidth
                value={descricaoTipo}
                InputLabelProps={{ shrink: true }}
                label={intl.formatMessage({ defaultMessage: "Descrição" })}
                placeholder={intl.formatMessage({
                  defaultMessage: "Descreva a atividade",
                })}
                inputProps={{ maxLength: 50 }}
                helperText={
                  salvando && descricaoTipo === ""
                    ? intl.formatMessage({
                        defaultMessage: "Campo obrigatório *",
                      })
                    : undefined
                }
                onChange={handlers.handleChangeDescricaoTipo}
              />
            </Flex>
          </Flex>
        )}
        <Flex>
          <Flex className={classes.divUmaColuna}>
            <Flex className={classes.divInput}>
              <div className={classes.divIconeInput}>
                <ActionDateRange color={corIconeInput} />
              </div>
              <TextField
                name="dia"
                id="dia"
                fullWidth
                inputProps={{ type: "date", min: estaEditando ? "" : new Moment().format("YYYY-MM-DD") }}
                value={dia}
                onChange={handlers.handleChangeDia}
                InputLabelProps={{ shrink: true }}
                label={intl.formatMessage({ defaultMessage: "Dia" })}
                required
                error={!!diaError}
                helperText={diaError}
              />
            </Flex>
          </Flex>
          <Flex className={classes.divInput} style={{ width: "50%" }}>
            <Flex className={classes.divIconeInput}>
              <DeviceAccessTime color={corIconeInput} />
            </Flex>
            <TextField
              name="horas"
              id="horas"
              fullWidth
              inputProps={{ type: "time" }}
              value={horas}
              onChange={handlers.handleChangeHoras}
              InputLabelProps={{ shrink: true }}
              label={intl.formatMessage({ defaultMessage: "Horas" })}
              required
              error={!!horasError}
              helperText={horasError}
            />
          </Flex>
        </Flex>
        <Flex className={classes.divInput}>
          <div className={classes.divIconeInput}>
            <CommunicationBusiness color={corIconeInput} />
          </div>
          <Flex flexDirection="column" style={{ width: "100%" }}>
            <AutoComplete
              underlineStyle={{ borderColor: empresaError ? "red" : "rgba(0, 0, 0, 0.42)" }}
              id="buscaEmpresaId"
              filter={AutoComplete.caseInsensitiveFilter}
              fullWidth
              floatingLabelText={intl.formatMessage({ defaultMessage: "Empresa" })}
              floatingLabelFixed
              dataSource={dataSourceLead}
              style={styles.empresaAutoComplete}
              dataSourceConfig={{ text: "nome", value: "id" }}
              onUpdateInput={handlers.handleOnUpdateInputEmpresa}
              onNewRequest={handlers.handleOnNewRequestEmpresa}
              searchText={textoPesquisaLead}
              disabled={foiCriadaPorWorkflow}
              errorText={empresaError}
              readOnly={leadReadOnly}
            />
            <LinearProgress mode="indeterminate" className={classes.barraProgresso} />
          </Flex>
        </Flex>
        <Flex alignItems="center" style={{ width: "100%" }}>
          <div className={classes.divIconeInput}>
            <ActionPermContactCalendar color={corIconeInput} />
          </div>
          <ChipInput
            floatingLabelFixed
            underlineStyle={{ borderColor: "rgba(0, 0, 0, 0.42)" }}
            disabled={Object.keys(lead).length === 0}
            fullWidth
            openOnFocus
            filter={AutoComplete.caseInsensitiveFilter}
            dataSource={contatosLead}
            dataSourceConfig={{ text: "descricao", value: "id" }}
            value={contatosSelecionados}
            floatingLabelText={intl.formatMessage({ defaultMessage: "Contatos" })}
            hintText={intl.formatMessage({
              defaultMessage: "Pesquisar...",
            })}
            onRequestAdd={handlers.handleOnRequestAddContato}
            chipRenderer={({ value, text, defaultStyle }) => (
              <Chip
                key={value}
                onRequestDelete={() => handlers.handleOnRequestDeleteContato(value)}
                style={defaultStyle}
              >
                <Avatar size={32}>{text ? text[0].toUpperCase() : ""}</Avatar>
                {text}
              </Chip>
            )}
          />
        </Flex>
        {isGerente && (
          <Flex className={classes.divInput}>
            <div className={classes.divIconeInput}>
              <ActionAccountCircle color={corIconeInput} />
            </div>
            <FormControl fullWidth disabled={leadReadOnly}>
              <InputLabel htmlFor="preVendedorId" shrink required error={!!pvError}>
                {isSpotterFull ? (
                  <FormattedMessage defaultMessage="Pré-Vendedor" />
                ) : (
                  <FormattedMessage defaultMessage="Usuário" />
                )}
              </InputLabel>
              <SelectScrollableMUI
                select
                fullWidth
                name="preVendedorId"
                id="preVendedorId"
                onChange={handlers.handleChangeVendedor}
                value={preVendedorSelecionado}
                error={!!pvError}
                helperText={pvError}
                endAdornment={!preVendedores?.length ? <CircularProgress color="primary" size={20} /> : undefined}
                disabled={!preVendedores?.length || leadReadOnly}
              >
                {preVendedores.map(({ id, descricao, ativo }) => (
                  <MenuItem key={id} value={id}>
                    {descricao}
                    {!ativo ? " (Inativo)" : ""}
                  </MenuItem>
                ))}
              </SelectScrollableMUI>
              {!!pvError && <FormHelperText error>{pvError}</FormHelperText>}
            </FormControl>
          </Flex>
        )}
        <Flex className={classes.divUmaColuna}>
          <Flex className={classes.divInput}>
            <div className={classes.divIconeInput}>
              <SocialNotifications color={corIconeInput} />
            </div>
            <TextField
              select
              label={intl.formatMessage({
                defaultMessage: "Notificar",
              })}
              id="notificarId"
              name="notificar"
              fullWidth
              value={notificacao}
              onChange={handlers.handleChangeTempoNotificacao}
            >
              <MenuItem value={0}>
                <FormattedMessage defaultMessage="Na hora agendada" />
              </MenuItem>
              <MenuItem value={5}>
                <FormattedMessage defaultMessage="5 minutos antes" />
              </MenuItem>
              <MenuItem value={15}>
                <FormattedMessage defaultMessage="15 minutos antes" />
              </MenuItem>
              <MenuItem value={30}>
                <FormattedMessage defaultMessage="30 minutos antes" />
              </MenuItem>
              <MenuItem value={60}>
                <FormattedMessage defaultMessage="1 hora antes" />
              </MenuItem>
              <Divider />
              <MenuItem value={-1}>
                <FormattedMessage defaultMessage="Não notificar" />
              </MenuItem>
            </TextField>
          </Flex>
        </Flex>
        {!isGerente && (
          <Flex className={classes.divCheckConcluirAtividade}>
            <Checkbox
              name="flConclusao"
              id="flConclusaoId"
              label={intl.formatMessage({
                defaultMessage: "Concluir atividade",
              })}
              iconStyle={{ marginRight: 8 }}
              checked={atividadeConcluida}
              onCheck={handlers.handlecheckedFlConcluirAtividade}
            />
          </Flex>
        )}
        {!isGerente &&
          atividadeConcluida &&
          foiCriadaPorWorkflow &&
          temProximaAtividadeEncadeada && (
            <Flex className={classes.divCheckNaoCriarProximaAtividade}>
              <Checkbox
                name="flNaoCriarProximaAtividade"
                id="flNaoCriarProximaAtividadeId"
                label={intl.formatMessage({ defaultMessage: "Não criar próxima atividade encadeada" })}
                iconStyle={{ marginRight: 8 }}
                checked={naoCriarProximaAtividade}
                onCheck={handlers.handlecheckedFlNaoCriarProximaAtividade}
              />
            </Flex>
          )}
        <Flex className={classes.divInputObservacoes}>
          <Flex style={{ width: 40, paddingTop: 20 }}>
            <ActionSpeakerNotes color={corIconeInput} />
          </Flex>
          <TextField
            name="observacao"
            id="observacaoId"
            InputLabelProps={{ shrink: true }}
            label={intl.formatMessage({ defaultMessage: "Observações" })}
            placeholder={intl.formatMessage({
              defaultMessage: "Faça suas observações",
            })}
            multiline
            fullWidth
            value={observacoes}
            rows={4}
            rowsMax={4}
            inputProps={{ maxLength: 2000 }}
            onChange={handlers.handleChangeObservacoes}
          />
        </Flex>
        <Flex
          justifyContent="flex-end"
          style={{ position: "sticky", bottom: 0, paddingBottom: 8, backgroundColor: "white" }}
        >
          <Button type="button" onClick={handlers.handleClose}>
            <FormattedMessage defaultMessage="Cancelar" />
          </Button>
          <Button type="button" color="primary" onClick={handlers.onSubmit}>
            <FormattedMessage defaultMessage="Salvar" />
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

ModalAtividadeNew.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  handlers: PropTypes.object,
  preVendedores: PropTypes.array,
  lead: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  contatosLead: PropTypes.array,
  contatosSelecionados: PropTypes.array,
  isGerente: PropTypes.bool,
  dataSourceLead: PropTypes.array,
  textoPesquisaLead: PropTypes.string,
  tipoAtividadeSelecionadaId: PropTypes.number,
  mostrarMensagemSucesso: PropTypes.bool,
  leadReadOnly: PropTypes.bool,
  classes: PropTypes.object,
  mensagemAtividade: PropTypes.string,
  notificacao: PropTypes.number,
  preVendedorSelecionado: PropTypes.number,
  salvando: PropTypes.bool,
  dia: PropTypes.string,
  horas: PropTypes.string,
  descricaoTipo: PropTypes.string,
  tipoAtividadeOutros: PropTypes.number,
  observacoes: PropTypes.string,
  atividadeConcluida: PropTypes.bool,
  naoCriarProximaAtividade: PropTypes.bool,
  foiCriadaPorWorkflow: PropTypes.bool,
  temProximaAtividadeEncadeada: PropTypes.bool,
  isDiaCorreto: PropTypes.bool,
  isHorasCorreta: PropTypes.bool,
  estaEditando: PropTypes.bool,
  intl: PropTypes.object,
};

const enhance = compose(
  injectSheet(styles),
  injectIntl
);
export default enhance(ModalAtividadeNew);
