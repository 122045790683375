import React, { Fragment, useState } from "react";
import { PropTypes } from "prop-types";
import { Dialog, IconButton } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import axios from "axios";
import { useIntl } from "react-intl";
import ModalExcluirAtividade from "./ModalExcluirAtividade";
import DialogCancelaWorkflow from "./DialogCancelaWorkflow";
import { createSnackbar, createDialogConfirm, AjaxBlackout } from "../../../_common";
import { TIPO_ACAO } from "../../workflow/constantes";
import { useSelectPermission } from "../../../hooks";
import permissions from "../../../_common/permissions";
import { ACCESS_LEVEL } from "../../../_common/constantes";
import { ActionButton } from "../../../components";

function ExcluirAtividade({ id, callback, styleIcon, useIconWithLabel = false }) {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const permissaoExcluir = useSelectPermission(permissions.MODULOATIVIDADES);

  function close(didRemove = true) {
    setOpen(false);
    callback(didRemove);
  }

  function criaAtividadeEncadeada(accepted, workflowPayloadDto) {
    if (accepted) {
      AjaxBlackout.Show();
      axios.post("/api/pipeline/workflow/CriarAcoes", workflowPayloadDto).then(() => {
        AjaxBlackout.Hide();
        close();
      });
    } else {
      close();
    }
  }

  const abrirModalExcluir = () => {
    if (permissaoExcluir.nivelAcesso !== ACCESS_LEVEL.NONE) {
      setOpen(true);
    } else {
      createSnackbar(intl.formatMessage({ defaultMessage: "Sem Permissão." }));
    }
  };

  function excluir() {
    AjaxBlackout.Show();
    axios.post(`/api/pipeline/atividade/cancelar?id=${id}`).then(({ data }) => {
      AjaxBlackout.Hide();
      createSnackbar(intl.formatMessage({ defaultMessage: "Atividade excluída com sucesso" }));
      if (data) {
        let titulo;
        let botaoConfirmar;
        let icone;
        let descricaoIcone;

        switch (data.workflowAcaoDto.tipoAcao) {
          case TIPO_ACAO.ENVIO_HSM:
            titulo = intl.formatMessage({ defaultMessage: "Enviar HSM (Workflow)" });
            botaoConfirmar = intl.formatMessage({ defaultMessage: "Enviar HSM" });
            icone = "whatsapp";
            descricaoIcone = data.workflowAcaoDto.nomeTemplateMensagemHsm;
            break;
          case TIPO_ACAO.CADENCIA:
            titulo = intl.formatMessage({ defaultMessage: "Inserir na Cadência de E-mails (Workflow)" });
            botaoConfirmar = intl.formatMessage({ defaultMessage: "Inserir na Cadência" });
            icone = "send";
            descricaoIcone = data.workflowAcaoDto.nomeCampanha;
            break;
          default:
            titulo = intl.formatMessage({ defaultMessage: "Atividade Encadeada (Workflow)" });
            botaoConfirmar = intl.formatMessage({ defaultMessage: "Criar Atividade" });
            icone = data.tipoAtividadeDto.icone;
            descricaoIcone = data.tipoAtividadeDto.descricao;
            break;
        }

        createDialogConfirm({
          text: intl.formatMessage({
            defaultMessage: "Ao excluir esta atividade, a ação encadeada abaixo será cancelada:",
          }),
          title: titulo,
          acceptLabel: botaoConfirmar,
          cancelLabel: intl.formatMessage({ defaultMessage: "Cancelar Workflow" }),
          content: <DialogCancelaWorkflow icon={icone} desc={descricaoIcone} />,
          callback: accepted => criaAtividadeEncadeada(accepted, data.workflowPayloadDto),
        });
      } else close();
    });
  }

  return (
    <Fragment>
      {useIconWithLabel ? (
        <ActionButton
          id="btn-excluir-atividade"
          type="menu"
          onClick={() => abrirModalExcluir()}
          icon={<Delete />}
          label={intl.formatMessage({ defaultMessage: "Excluir" })}
        />
      ) : (
        <IconButton onClick={() => abrirModalExcluir()}>
          <Delete style={styleIcon} />
        </IconButton>
      )}
      <Dialog open={open} onClose={() => close(false)}>
        <ModalExcluirAtividade cancelar={() => close(false)} excluir={excluir} />
      </Dialog>
    </Fragment>
  );
}

ExcluirAtividade.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callback: PropTypes.func,
  styleIcon: PropTypes.object,
  useIconWithLabel: PropTypes.bool,
};

export default ExcluirAtividade;
