import { useState, useEffect } from "react";

import { useEvent } from "../../../../hooks";

const createBroadcastChannel = onClose => {
  if (!window.sugerirConclusaoAtividadeBroadcast) {
    const bc = new BroadcastChannel("SugerirConclusaoAtividade");
    bc.onmessage = ({ data }) => {
      if (data === "SugerirConclusaoAtividade.CloseAll") {
        onClose();
      }
    };
    window.sugerirConclusaoAtividadeBroadcast = bc;
  }
};

export default function useAtividadeSugerirConclusao() {
  const [state, setState] = useState({
    isOpen: false,
    atividades: [],
    nomeLead: "",
  });

  const onClose = () => setState(prevState => ({ ...prevState, isOpen: false }));

  useEvent("SpotterSugerirConclusaoAtividade", ({ detail }) => {
    const { atividades, lead } = detail;

    if (atividades?.length) {
      setState({
        isOpen: true,
        atividades: atividades.map(atividade => ({ ...atividade, lead })),
        nomeLead: lead.nome,
      });
    }
  });

  useEffect(() => {
    createBroadcastChannel(onClose);
  }, []);

  const closeInAllTabs = () => {
    window.sugerirConclusaoAtividadeBroadcast.postMessage("SugerirConclusaoAtividade.CloseAll");
    onClose();
  };

  return {
    ...state,
    closeInAllTabs,
    onClose,
  };
}
