import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Avatar,
  Icon,
  Button,
  Typography,
  withStyles,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import { Flex } from "../../../../_common";

function ModalConflitoAtividade({ isOpen, conflitos, callback, text, title = "Alerta", classes }) {
  return (
    <Dialog open={isOpen} scroll="paper" maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>

        {!conflitos?.length && <Typography variant="subtitle1">Nenhum conflito.</Typography>}

        {!!conflitos?.length &&
          conflitos.map(atividade => (
            <Flex key={atividade.id} className={classes.linhaAtividade}>
              <Avatar style={{ marginRight: 24 }}>
                <Icon>{atividade.icone}</Icon>
              </Avatar>
              <Flex flexDirection="column" className={classes.colunaTipo}>
                <Typography>{atividade.tipo}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {atividade.dataFormatada}
                </Typography>
              </Flex>
              <Flex flexDirection="column" className={classes.colunaLead}>
                <Typography>{atividade.leadNome}</Typography>
              </Flex>
            </Flex>
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => callback(false)}>
          <FormattedMessage defaultMessage="Cancelar" />
        </Button>
        <Button onClick={() => callback(true)} color="primary">
          <FormattedMessage defaultMessage="Manter mesmo assim" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalConflitoAtividade.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
  conflitos: PropTypes.array,
  classes: PropTypes.object,
};

export default withStyles({
  linhaAtividade: { paddingTop: 16 },
  colunaTipo: { paddingLeft: 8, width: "40%" },
  colunaLead: { paddingLeft: 8, width: "60%" },
})(ModalConflitoAtividade);
