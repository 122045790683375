import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  withStyles,
  Dialog,
  DialogContent,
  Avatar,
  Icon,
} from "@material-ui/core";
import { useIntl } from "react-intl";

import { DialogTitleClose } from "../../../../_common";
import useAtividadeSugerirConclusao from "./useAtividadeSugerirConclusao";
import { ConcluirAtividadeButton, useConcluirAtividade } from "../ConcluirAtividade";
import ModalAtividadeNewCont from "../ModalAtividadeNewCont";
import { Chip } from "../../../../components";
import { getLabelAtividade, getTooltipAtividade } from "../../utils";
import ModalConcluirAtividadeEncadeada from "../../../atividades/modals/ModalConcluirAtividadeEncadeada";

function AtividadeSugerirConclusao({ classes }) {
  const intl = useIntl();
  const { isOpen, onClose, nomeLead, atividades, closeInAllTabs } = useAtividadeSugerirConclusao();
  const { concluirAtividade, modalConcluirAtividadeProps, verificaAcaoEncadeadaWF } = useConcluirAtividade(
    closeInAllTabs
  );

  const handleClickConcluir = atividade =>
    ModalConcluirAtividadeEncadeada(atividade, concluirAtividade, intl, verificaAcaoEncadeadaWF);

  return (
    <>
      {modalConcluirAtividadeProps.open && (
        <ModalAtividadeNewCont
          {...modalConcluirAtividadeProps}
          callback={success => {
            if (success) {
              document.dispatchEvent(new Event("atividades/lista/reload"));
              document.dispatchEvent(new Event("dashboard/atividades/reload"));
              document.dispatchEvent(new Event("detalhe-lead/timeline/reload"));
              document.dispatchEvent(new Event("pipeline/reload"));
            }
          }}
        />
      )}

      {isOpen && (
        <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={() => onClose()}>
          <DialogTitleClose onClose={() => onClose()}>
            {intl.formatMessage({ defaultMessage: "Concluir Atividade" })}
          </DialogTitleClose>
          <DialogContent>
            <Typography>
              {intl.formatMessage(
                {
                  defaultMessage: "Atividades próximas do horário atual para o lead <b>{nomeLead}</b>",
                },
                { nomeLead }
              )}
            </Typography>
            <List className={classes.list}>
              {atividades.map(atividade => (
                <ListItem className={classes.listItem} key={atividade.id}>
                  <Avatar className={classes.avatar}>
                    <Icon>{atividade.icone}</Icon>
                  </Avatar>
                  <ListItemText
                    disableTypography
                    primary={<Typography>{atividade.tipo}</Typography>}
                    secondary={
                      <Chip
                        tooltip={getTooltipAtividade(atividade)}
                        label={getLabelAtividade(atividade)}
                        status={atividade.status}
                      />
                    }
                  />
                  <div className={classes.listItemActions}>
                    <ConcluirAtividadeButton onClick={() => handleClickConcluir(atividade)} />
                  </div>
                </ListItem>
              ))}
            </List>
            {!atividades.length && (
              <Typography> {intl.formatMessage({ defaultMessage: "Nenhuma atividade encontrada" })}.</Typography>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default withStyles(theme => ({
  modalBody: {
    padding: "0 0 24px !important",
  },
  list: {
    maxHeight: 400,
    overflow: "auto",
    marginTop: 16,
    paddingTop: 0,
  },
  listItem: {
    position: "relative",
    "&:hover": {
      backgroundColor: theme.palette.backgroundRowHover,
    },
    "&:hover $listItemActions": {
      visibility: "visible",
      pointerEvents: "auto",
    },
  },
  listItemActions: {
    visibility: "collapse",
    pointerEvents: "none",
    right: 12,
    position: "absolute",
  },
  avatar: {
    marginRight: 24,
  },
}))(AtividadeSugerirConclusao);
